import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { hydrate, render } from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/custom.css';
import '../src/assets/css/fontawesome.css';
import '../src/assets/css/animate.min.css';
import '../src/assets/css/placeholder-loading.min.css';
import axios from 'axios'

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
if (root.hasChildNodes()) {
    hydrate(<App />, root);
} else {
    render(<App />, root);
}
serviceWorker.unregister();

